<template>
  <main class="page-users page-edit-user">
    <PageTitleContainer>
      <PageTitle subtitle="Modifica" removeMargin>{{ user.name }} {{ user.surname }}</PageTitle>

      <div class="text-right mb-3">
        <router-link class="btn btn-outline-secondary" :to="{ name: 'users.show' }">Annulla modifica</router-link>
      </div>
    </PageTitleContainer>
    <div class="container">
      <UserForm :feedback.sync="feedback" :user="userToEdit" @submitForm="submit" edit />
    </div>
  </main>
</template>

<script>

export default {
  name: 'user-edit',
  components: {
    PageTitle: () => import('../../components/Layout/PageTitle'),
    PageTitleContainer: () => import('../../components/Layout/PageTitleContainer'),
    UserForm: () => import('../../components/Form/UserForm'),
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      userToEdit: {},
      feedback: null,
    };
  },
  methods: {
    updateUserToEdit (user) {
      this.userToEdit = { ...user, ...this.userToEdit };
    },
    submit (userToEdit) {
      const user = { ...userToEdit };

      this.$api.updateOperator(user.id, user)
        .then(res => {
          this.feedback = true;
          this.$emit('update:user', res.data);
          this.userToEdit = {};
          this.$router.push({ name: 'users.show' });
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
  },
  mounted () {
    this.updateUserToEdit(this.user);
  },
  watch: {
    user (newValue) {
      this.updateUserToEdit(newValue);
    },
  },
};

</script>
